<template>
  <div>
    <div class="div_bg">
      <img class="bg" src="@/assets/bg.svg" />
    </div>
    <header>
      <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
        <div class="container-fluid">
          <a class="navbar-brand" href="#"><img class="logo" src="@/assets/EN.svg" /> </a>
          <button v-on:click="toggleMenu"  class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <img class="icon" src="@/assets/svg/list.svg" />
          </button>
          <div class="collapse navbar-collapse" :class="{show:showMenu}" id="navbarCollapse">
            <div class="me-auto"></div>
            
            <a class="act_btn_top" :href="'accounting/index.html#/signup'">{{ $t("try") }}</a> &nbsp; &nbsp; 
            <a class="act_btn_top" :href="'accounting/index.html'">{{ $t("sign in") }}</a>
            <span class="lang" @click="changeLocale">{{ $t("en") }}</span>
          </div>
        </div>
        <div class="lower_link collapse navbar-collapse" :class="{show:showMenu}">
          <!--<div class="me-auto"></div>-->
          <ul class="navbar-nav">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#">{{ $t("home") }}</a>
              </li>
              
              <li class="nav-item">
                <router-link to="" @click="goTo('services')"  class="nav-link" >
                  {{ $t("services") }}
                </router-link>
              </li>
              <!-- features -->
              <li class="nav-item">
                <router-link to="" @click="goTo('business')"  class="nav-link" >
                  {{ $t("features") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="" @click="goTo('pricing')"  class="nav-link" >
                  {{ $t("packages and prices") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="" @click="goTo('usecases')"  class="nav-link" >
                  {{ $t("business fields") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="" @click="goTo('customers')"  class="nav-link" >
                  {{ $t("customers") }}
                </router-link>
              </li>
              <!-- usecases -->
              <li class="nav-item">
                <router-link to="" @click="goTo('features')"  class="nav-link" >
                  {{ $t("lessons") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="" @click="goTo('faq')"  class="nav-link" >
                  {{ $t("faq") }}
                </router-link>
              </li>
              
            </ul>
        </div>
      </nav>

    </header>
    <div class = "call_to_action">
        <div class="act_1">
          {{ $t("break root") }}
        </div>
        <div class="act_2">
          {{ $t("break root more") }}
        </div>
        
        <a class="act_btn" :href="'accounting/index.html#/signup'">{{ $t("get started") }}</a>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      showMenu:false,
      locale: 'En'
    }
  },
  mounted () {
    let obj = this;
    document.title = obj.$t("Kosoor");
  },
   methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
    changeLocale(){
      let obj = this;
      const html = document.documentElement
      if(this.locale == 'En'){
        this.locale = 'Ar'
        html.setAttribute('lang', 'ar');
        html.setAttribute('dir', 'rtl');
        obj.$store.commit("setLang", 'ar');
        obj.$i18n.locale = 'ar';
      } else {
        this.locale = 'En'
        html.setAttribute('lang', 'en');
        html.setAttribute('dir', '');
        obj.$store.commit("setLang", 'en');
        obj.$i18n.locale = 'en';
      }
      document.title = obj.$t("Kosoor");
    },
    goTo(e){
      var elementTo = document.getElementById(e);
      elementTo.scrollIntoView({ block: 'center',  behavior: 'smooth' });
    }
  }
}
</script>

<style scoped>
header{
  position: relative;
  z-index: 1;
}
nav{
  display: block;
}
.navbar{
  opacity: 0.9;
  /*background:#dceaee !important;*/
}
.navbar-nav{
  display: flex;
}
.lower_link{
  display: block;
}
.nav-item{
  padding: 0 1em;
}
.nav-link{
  text-transform: uppercase;
  font-weight: bold;
  color:#fff;
  font-size: 12px;
}
.nav-link:hover{
  color:#eee;
}
.call_to_action{
  margin-top: 15em;
  border-bottom:solid 1px #ccc;
  padding:2em;
}
.act_1{
  font-size: 3em;
  font-weight: bold;
  line-height: 1em;
  text-transform: uppercase;
  width: 10em;
  text-align: left;
}

.act_2{
  line-height: 1em;
  width: 27em
}


.act_btn{
  margin-top: 1em;
  margin-bottom: 9em;
  display: inline-block;
}

.act_btn_top{
  border:1px;
  border-radius: 1em;
  padding:0.5em 1em;
  background: #fff;
  color:#2d398f;
}
.lang{
  border:solid 1px #eee;
  margin: 0 0.5em;
  border-radius: 1em;
  padding:0.2em 0.5em;
  color:#fff;
  cursor: pointer;
}

.div_bg {
  text-align: right;
  position:absolute;
  top:0;
  z-index: -1;
  width:100%;
  height: 700px;
}
.div_bg:lang(ar){
  text-align: left;
}

.bg{
  width: 70%;
}
.bg:lang(ar){
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.logo{
    height: 56px;
}

.icon{
    height: 28px;
    filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(86deg) brightness(118%) contrast(119%);
}



@media(max-width:744px) {
    /*.navbar .navbar-nav>.nav-item {
        float: none;
        margin-left: .1rem;
    }*/
    .container-fluid{
      padding: 0;
    }
    
    .navbar .collapse{
      background: rgba(120, 123, 89, .6);
      padding: 0 0 1em 1em ;
    }
}

</style>
