<template>
  <div class="feature" id="customers">
    <div class="container">

      <div class="row">
        <div class="col-md-12">
          <h1 class="heading">{{ $t("our customers") }}</h1>
          <div class="comment">
              <p>
                Another exciting bit of representative placeholder content. This time, we've moved on to the second column.
              </p>
          </div>
        </div>
      </div>
      <div class="logos">
          <div class="row">
            <div class="col-md-12">
              <img class="img" src="@/assets/clients.png" />
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Customers'
}
</script>

<style scoped>
.feature{
  margin: 3em 0;
  padding-bottom: 3em;
  text-align-last: center;
}
.heading{
  text-transform: uppercase;
  text-align: center;
  padding: 1em 0;
}


.icon{
    width: 100px;
    margin-bottom: 1em
}
.img{
  width: 100%;
}
.comment p{
  padding: 0 25%;
  margin-bottom: 3em
}

.logos{
  padding-top: 3em;
}
</style>
