<template>
  <div class="plan" id="pricing">
    <div class="container">
      <div class="switch">
        <button type="button" class="btn btn-sm btn-toggle" :class="{active:monthly}" @click="monthly =! monthly">
          <div class="handle"></div>
        </button> <span :class="{textactive:monthly == false}">{{ $t("annually") }}</span>/<span :class="{textactive:monthly == true}">{{ $t("monthly") }}</span>
      </div>
      <div class="row">

        <div class="col-lg-4 item" v-for="row in results" v-bind:key="row" :class="{active:row.active}" @mouseenter="removeActive" @mouseleave="restoreActive" >
          <div class="div_bg" v-if="row.soon">
            <img class="bg" src="@/assets/soon.svg" />
          </div>

          <h2>{{$store.state.lang == 'en'?row.nameEn:row.nameAr}}</h2>
          <p>{{$store.state.lang == 'en'?row.labelEn:row.labelAr}}</p>
          <Services :package="row.id" :services="services" :mapper="mapper" />
          <div class="price">
            <sup>SR</sup> {{monthly?row.monthlyPrice:row.yearlyPrice}} <span>/{{monthly?$t("month"):$t("year")}}</span>
          </div>
          <button class="act_btn">{{ $t("subscribe") }}</button>
        </div>

      </div>
      <div class="addon">
        <h1 class="heading">{{ $t("add on") }}</h1>
        <table class="table">
          <tr>
            <td></td>
            <td>{{ $t("includes") }}</td>
            <td>{{ $t("price") }}</td>
          </tr>
          <tr v-for="row in addon" v-bind:key="row">
            <td>{{$store.state.lang == 'en'?row.nameEn:row.nameAr}}</td>
            <td>{{$store.state.lang == 'en'?row.labelEn:row.labelAr}}</td>
            <td>{{monthly?row.monthlyPrice:row.yearlyPrice}}<sup>SR</sup>/ {{monthly?$t("month"):$t("year")}} </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { backendUrl } from '@/config';
import axios from 'axios';

import Services from '@/components/Services.vue'

export default {
  name: 'Plan',
  components: {
    Services
  },
  data() {
    return {
      loading: false,
      success: false,
      output:null,
      results: null,
      services: null,
      mapper: null,
      addon: null,
      monthly:true
    }
  },
  mounted () {
    //alert("");
    let obj = this;
    axios
    .get(backendUrl+'home/packages', {})
    .then(response => {
      obj.results = response.data.results;
      obj.services = response.data.services;
      obj.mapper = response.data.mapper;
      obj.addon = response.data.addon;
      //console.log(response.data)
      
      for(var i in obj.results){
        obj.results[i].active = false;
      }

      if(obj.results.length > 1){
        //make middle active
        obj.results[1].active = true;
      } 
    })
    .catch(error => {
      alert(error.message)
    })
    .finally(() => obj.loading = false)
  }
  ,
	methods: {
		removeActive() {
      let obj = this;
			for(var i in obj.results){
        obj.results[i].active = false;
      }
		},
    restoreActive(){
      let obj = this;
      if(obj.results.length > 1){
        obj.results[1].active = true;
      } 
    }
	}
}
</script>

<style scoped>
.div_bg {
  text-align: right;
  position:absolute;
  top:0;
  z-index: 0;
  width:100%;
  height: 70px;
}

.bg{
  width: 30%;
}

.plan{
  margin: 3em 0;
  text-align: center;
  border-bottom:solid 1px #ccc;
  padding-bottom: 3em;
}
.heading{
  text-transform: uppercase;
}
.item {
  border:solid 1px #ccc;
  margin: 3em 0;
  padding-bottom: 1em;
  position: relative;
  overflow: hidden;
}
.item:first-child{
  border-right: 0;
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
}

.item:last-child{
  border-left: 0;
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}

.item:lang(ar){
  border:solid 1px #ccc;
  border-radius: 0.5em;
}

.item  h2{
 padding: 1em 0 0 0;
}

.item  .act_btn{
  display: none;
}

.active .act_btn, .item:hover .act_btn{
  display:inline-block;
}

.active{
  background:#dceaee;
  margin: 0 0;
  border:none;
}
.textactive{
  font-weight: bold;
}
.item:hover{
  background:#dceaee;
  margin: 0 0;
  border:none;
}

.price{
  font-size: 2em;
  font-weight: bold;
  padding: 1em 0;
}
.price span{
  font-size: 0.5em;
  font-weight: normal;
}
.switch{
  padding-bottom: 20px;
}
.addon{
  padding-top: 10px;
}
.addon table{
  color:#2d398f;
}
.addon table tr td{
  border:none;
  padding:10px;
  width:33%
}
.addon table tr:first-child td, .addon table tr td:first-child{
  background: #2d398f;
  color:#fff;
  border:solid 2px #fff;
  font-weight: bold;
  font-size: 20px;
}

.addon table tr:first-child td:first-child{
  background: none;
}
/* toggle button */
.btn-toggle {
  margin: 0 4rem;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
  color: #6b7381;
  background: #2d398f;
}
.btn-toggle:focus, .btn-toggle:focus.active, .btn-toggle.focus, .btn-toggle.focus.active {
  outline: none;
}
.btn-toggle:before, .btn-toggle:after {
  line-height: 1.5rem;
  width: 4rem;
  text-align: center;
  font-weight: 600;
  font-size: .75rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  transition: opacity .25s;
}
.btn-toggle:before {
  /*content: 'Off';*/
  left: -4rem;
}
.btn-toggle:after {
  /*content: 'On';*/
  right: -4rem;
  opacity: .5;
}

.btn-toggle.active {
  transition: background-color .25s;
}

.btn-toggle.active:before {
  opacity: .5;
}
.btn-toggle.active:after {
  opacity: 1;
}
.btn-toggle.btn-sm:before, .btn-toggle.btn-sm:after {
  line-height: -0.5rem;
  color: #fff;
  letter-spacing: .75px;
  left: 0.4125rem;
  width: 2.325rem;
}
.btn-toggle.btn-sm:before {
  text-align: right;
}
.btn-toggle.btn-sm:after {
  text-align: left;
  opacity: 0;
}
.btn-toggle.btn-sm.active:before {
  opacity: 0;
}
.btn-toggle.btn-sm.active:after {
  opacity: 1;
}
.btn-toggle:before, .btn-toggle:after {
  color: #6b7381;
}
.btn-toggle.active {
  background-color: #2d398f;
}

.btn-toggle.btn-sm {
  margin: 0 .5rem;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
}
.btn-toggle.btn-sm:focus, .btn-toggle.btn-sm:focus.active, .btn-toggle.btn-sm.focus, .btn-toggle.btn-sm.focus.active {
  outline: none;
}
.btn-toggle.btn-sm:before, .btn-toggle.btn-sm:after {
  line-height: 1.5rem;
  width: .5rem;
  text-align: center;
  font-weight: 600;
  font-size: .55rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  transition: opacity .25s;
}
.btn-toggle.btn-sm:before {
  /*content: 'Off';*/
  left: -0.5rem;
}
.btn-toggle.btn-sm:after {
  /*content: 'On';*/
  right: -0.5rem;
  opacity: .5;
}
.btn-toggle.btn-sm > .handle {
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1.125rem;
  background: #fff;
  transition: left .25s;
}
.btn-toggle.btn-sm.active {
  transition: background-color .25s;
}
.btn-toggle.btn-sm.active > .handle {
  left: 1.6875rem;
  transition: left .25s;
}
.btn-toggle.btn-sm.active:before {
  opacity: .5;
}
.btn-toggle.btn-sm.active:after {
  opacity: 1;
}
.btn-toggle.btn-sm.btn-sm:before, .btn-toggle.btn-sm.btn-sm:after {
  line-height: -0.5rem;
  color: #fff;
  letter-spacing: .75px;
  left: 0.4125rem;
  width: 2.325rem;
}
.btn-toggle.btn-sm.btn-sm:before {
  text-align: right;
}
.btn-toggle.btn-sm.btn-sm:after {
  text-align: left;
  opacity: 0;
}
.btn-toggle.btn-sm.btn-sm.active:before {
  opacity: 0;
}
.btn-toggle.btn-sm.btn-sm.active:after {
  opacity: 1;
}
</style>
