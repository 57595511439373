<template>
  <ul class="list-unstyled">
    <li v-for="row in results" v-bind:key="row">
      <img class="icon" src="@/assets/svg/check.svg" /> {{$store.state.lang == 'en'?row.nameEn:row.nameAr}}
    </li>
  </ul>  
</template>

<script>
export default {
  name: 'Services',
  props: ['package', 'services', 'mapper'],
  data() {
    return {
      results: []
    }
  },
  mounted () {
    let obj = this;
    for(var i in obj.services){
      for(var j in obj.mapper){
        if(obj.package == obj.mapper[j].packagesId && obj.services[i].id == obj.mapper[j].servicesId){
          obj.results.push(obj.services[i]);
        }
      }
    }
    //console.log(obj.results);
  }
}
</script>

<style scoped>
ul{
  width:16em;
  margin: 0 auto;
}
ul li{
  text-align: left;
}
</style>
