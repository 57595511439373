<template>
  <div class="cover" id="faq">
    <!--
    <div class="heading">
      <span>Do you need custom offer?</span> <button class="act_btn">{{ $t("contact us") }}</button>
    </div>
    -->
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <h2>{{ $t("talk on phone") }}</h2>
            <p>920012104</p>
          </div>

          <div class="col-lg-6 sep">
            <h2>{{ $t("get intouch by email") }}</h2>
            <p>info@kosoor.com.sa</p>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact'
}
</script>

<style scoped>
.cover{
  margin: 3em 0;
  text-align: center;
  padding-bottom: 3em;
}
.heading{
  /*text-transform: uppercase;*/
  background: #2d398f;
  color:#fff;
  padding: 1.2em 0;
}

.heading span{
  font-size: 2em;
}

.content{
  background:#333;
  color:#fff;
}

.content .col-lg-6{
  padding: 4em 0;
}
.sep{
  border-left: solid 1px #444;
}

.act_btn{
  border:solid 1px #fff;
  margin-left: 3em;
  
}

</style>
