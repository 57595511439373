<template>
  <div class="cover">
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <h4>{{ $t("contact us") }}</h4>
            <p>info@kosoor.com.sa <br>920012104</p>
          </div>

          <div class="col-lg-4">
            <h4>Follow us</h4>
            <p>
              <img class="icon" src="@/assets/svg/google.svg" />
              <img class="icon" src="@/assets/svg/facebook.svg" />
              <img class="icon" src="@/assets/svg/twitter.svg" />
              <img class="icon" src="@/assets/svg/instagram.svg" />
            </p>
          </div>

          <div class="col-lg-4">
            <h4>{{ $t("newsletter") }}</h4>
            <div class="input-group">
              <input type="text" class="form-control" />
              <span class="input-group-text">{{ $t("sign up") }}</span>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="heading">
      &copy; 2021 by Middle East Advanced Telcom and Information Technology Company
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
.cover{
  
  text-align: center;
}
.heading{
  padding: 1.2em 0;
  border-top: solid 1px #ccc;
}

.heading span{
  font-size: 2em;
}

.icon{
    height: 18px;
    margin: 0 1em
}

.content .col-lg-4{
  padding: 4em 0;
}

.input-group input, .input-group-text{
  border-radius: 3em !important;
}
.input-group input{
  margin-left: 10%;
  background: #dceaee;
}
.input-group .input-group-text{
  margin-left: -2em !important;
  z-index: 1;
  background: #2d398f;
  color:#fff;
  padding-left:2em;
  padding-right:2em;
}

</style>
