<template>
  <div>
    <Header /> 
    <main>
      <FeatureOne />
      <Plan /> 
      <FeatureTwo />
      <FeatureThree />
      <FeatureFour />
      <Usecases />
      <Customers />
      <Contact />
      <Footer />
    </main>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import FeatureOne from '@/components/FeatureOne.vue'
import Plan from '@/components/Plan.vue'
import FeatureTwo from '@/components/FeatureTwo.vue'
import FeatureThree from '@/components/FeatureThree.vue'
import FeatureFour from '@/components/FeatureFour.vue'
import Usecases from '@/components/Usecases.vue'
import Customers from '@/components/Customers.vue'
import Contact from '@/components/Contact.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    FeatureOne,
    Plan,
    FeatureTwo,
    FeatureThree,
    FeatureFour,
    Usecases,
    Customers,
    Contact,
    Footer
  }
}
</script>
