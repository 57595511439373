<template>
  <div class="feature" id="features">
    <div class="container">
      
      <div class="row">
        <div class="col-lg-6">
          <img class="kosoor" src="@/assets/interface.png" />
          <!--
          <div :style="{'background-image': 'url(' + require('@/assets/interface.png') + ')', 'height':'420px'}">
           &nbsp;
          </div>
          -->
        </div>

        <div class="col-lg-6">
          <div class="feature_items">
            <h2><img class="icon" src="@/assets/svg/check-circle.svg" /> {{ $t("amazing experience") }}</h2>
            <p>The interface is simple and intuitive for everyone and we work continuously to make it more simpler.</p>
          </div>

          <div class="feature_items">
            <h2><img class="icon" src="@/assets/svg/check-circle.svg" /> {{ $t("instant update") }}</h2>
            <p>We live in the real world, and so do your data. So you'll always know what's going on.</p>
          </div>

          <div class="feature_items">
            <h2><img class="icon" src="@/assets/svg/check-circle.svg" /> {{ $t("use any device") }}</h2>
            <p>Cloud-base application with KOSOOR, you can have your own business center at anywhere.</p>
          </div>
          <a class="act_btn" :href="'accounting/index.html#/signup'">{{ $t("try now") }}</a> <button class="act_btn">Google Play</button>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeatureTwo'
}
</script>

<style scoped>
.feature{
  margin: 3em 0;
  padding-bottom: 3em;
}
.heading{
  text-transform: uppercase;
}

.feature_items h2{
 padding: 0 0 0.4em 0;
}

.feature_items p{
  padding: 0 0 1.5em 2.2em;
  
}

.act_btn{
  margin-left: 2em;
}
.kosoor{
  width:100%
}
</style>
