<template>
  <div class="feature" id="services">

    <div id="myCarousel" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button v-for="(row, index) in items" v-bind:key="row" @click="play(index, 'none')" type="button" data-bs-target="#myCarousel" :data-bs-slide-to="index" :class="{active:row.active}" :aria-current="row.active" aria-label="Slide 1"></button>
      <!--
      <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
      -->
    </div>
    <div class="carousel-inner">

      <div v-for="row in items" v-bind:key="row" class="carousel-item" :class="{active:row.active}" >
        <img  class="bd-placeholder-img" :src="row.img" />
        <div class="pos">
          <div><img class="icon" src="@/assets/svg/laptop.svg" /></div>
          <h1>{{$store.state.lang == 'en'?row.nameEn:row.nameAr}}</h1>
          <p>
              {{$store.state.lang == 'en'?row.descriptionEn:row.descriptionAr}}
          </p>
          <button class="act_btn">{{ $t("try now") }}</button>
          
        </div>

      </div>
      
    </div>
    <button @click="play(0, 'prev')" class="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button @click="play(0, 'next')" class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
  
    

  </div>
</template>

<script>
import { backendUrl } from '@/config';
import axios from 'axios';

export default {
  name: 'FeatureFour',
  data() {
    return {
      items: [{name:'We here', img:require('@/assets/office_one.jpeg'), active:true}, {name:'The three here', img:require('@/assets/office_two.jpeg'), active:false}]
    }
  },
  mounted () {
    //alert("");
    let obj = this;
    axios
    .get(backendUrl+'home/products', {})
    .then(response => {
      obj.items = response.data.results;
      for(var i in obj.items){
        if(i == 0){
          obj.items[i].active = true;
        }
        obj.items[i].img = (i%2 == 1)?require('@/assets/office_one.jpeg'):require('@/assets/office_two.jpeg');
      }
      console.log(obj.items);
    })
    .catch(error => {
      alert(error.message)
    })
    .finally(() => obj.loading = false)
  },
  methods: {
    
    play(index, type) {
      var obj = this;
      var current = 0;
      for(var i in obj.items){
        if(obj.items[i].active == true){
          current = i;
        }
        obj.items[i].active = false;
      }

      switch(type){
        case 'none':
          obj.items[index].active = true;
          break;
        case 'next':
            var new_current = eval(current) + 1;
            if(obj.items.length <= new_current){
              new_current = 0;
            }
            obj.items[new_current].active = true;
          break;
        case 'prev':
            var p_new_current = eval(current) - 1;
            if(p_new_current < 0){
              p_new_current = obj.items.length - 1;
            }
            obj.items[p_new_current].active = true;
          break;
      }
     
    }
  }
}

</script>

<style scoped>

.carousel-item img{
  opacity: 0;
}

.active img {
  opacity: 1;
  transition: 3s;
}

.feature{
  margin: 0em 0;
  padding-bottom: 3em;
  position:relative;
  height: 35em;
  overflow: hidden;
  border-bottom:solid 1px #ccc;
}

.heading{
  text-transform: uppercase;
  text-align: center;
  padding: 1em 0;
}

.feature_items p{
  padding: 0 0 1.5em 0.2em;
}

.plan_1, .plan_3 {
  padding: 0 3em;
}

.plan_2 {
  text-align: center;
  padding-top: 8em;
}

.pos{
  background: rgba(0, 0, 130, 0.8);
  width:30em;
  position:absolute;
  right:10%;
  top:1em;
  color:#fff;
  text-align: center;
  padding-bottom: 3em;
}

.pos p{
  padding: 1.5em;
}

.act_btn{
background: #fff;
color:#2d398f
}

.icon{
    height: 89px;
    margin: 0.5em 0;
    filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(86deg) brightness(118%) contrast(119%);
}

.carousel-item img{
  width:100%
}
.carousel-indicators{
  bottom:5em;
}
.carousel-control-prev,  .carousel-control-next{
  width: auto;
}

.carousel-control-prev .carousel-control-prev-icon, .carousel-control-next .carousel-control-next-icon{
  border:solid 1px #2d398f;
  padding: 40px 20px;
  background-color: #2d398f;
}

</style>
