let hostpath = document.location.hostname.split(".");
var backendUrl = "https://kosoor.com.sa/"
switch(hostpath[0]){
    case 'localhost':
        backendUrl = "https://localhost:5001/"
        break;
    case 'www':
        backendUrl = "https://www.kosoor.com.sa/"
        break;
    case 'accountingmain':
        backendUrl = "https://accountingmain.azurewebsites.net/"
        break;
    case 'accountingmain-test':
        backendUrl = "https://accountingmain-test.azurewebsites.net/"
        break;
    case 'accountingmaintest':
        backendUrl = "https://accountingmaintest.azurewebsites.net/"
        break;
}

export {
    backendUrl
}