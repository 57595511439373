<template>
  <div class="feature" id="usecases">
    <div class="container">

      <div class="row">
        <div class="col-md-12">
          <h1 class="heading">Use Cases</h1>
        </div>
      </div>
     
     <div class="row">
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/basket.svg" />
          </div>
          <div>{{ $t("supermarkets") }}</div>
      </div>
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/book.svg" />
          </div>
          <div>{{ $t("stationery") }}</div>
      </div>
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/briefcase.svg" />
          </div>
          <div>{{ $t("laundry") }}</div>
      </div>
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/award.svg" />
          </div>
          <div>{{ $t("sewing shops") }}</div>
      </div>
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/bank.svg" />
          </div>
          <div>{{ $t("barbers") }}</div>
      </div>
    </div>
    <div class="row">
      <div class="col">
          <div class="box">
          <img class="icon" src="@/assets/svg/droplet.svg" />
          </div>
          <div>{{ $t("gas stations") }}</div>
      </div>
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/wallet.svg" />
          </div>
          <div>{{ $t("buffets") }}</div>
      </div>
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/shop.svg" />
          </div>
          <div>{{ $t("cafes and restaurants") }}</div>
      </div>
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/arrow-down-up.svg" />
          </div>
          <div>{{ $t("spare parts") }}</div>
      </div>
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/sim.svg" />
          </div>
          <div>{{ $t("mobiles and electronics") }}</div>
      </div>
    </div>
    <div class="row">
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/gift.svg" />
          </div>
          <div>{{ $t("gifts and haberdashery") }}</div>
      </div>
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/cloud-snow.svg" />
          </div>
          <div>{{ $t("cosmetics and perfume") }}</div>
      </div>
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/paint-bucket.svg" />
          </div>
          <div>{{ $t("paints and building") }}</div>
      </div>
      <div class="col">
          <div class="box">
          <img class="icon" src="@/assets/svg/building.svg" />
          </div>
          <div>{{ $t("beauty centers") }}</div>
      </div>
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/watch.svg" />
          </div>
          <div>{{ $t("gold and jewelry") }}</div>
      </div>
    </div> 

    <div class="row">
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/columns.svg" />
          </div>
          <div>{{ $t("clothes shops") }}</div>
      </div>
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/minecart.svg" />
          </div>
          <div>{{ $t("spices shops") }}</div>
      </div>
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/flower1.svg" />
          </div>
          <div>{{ $t("fruit and vegetable stores") }}</div>
      </div>
      <div class="col">
          <div class="box">
          <img class="icon" src="@/assets/svg/eye.svg" />
          </div>
          <div>{{ $t("eyeglasses stores") }}</div>
      </div>
      <div class="col">
          <div class="box">
            <img class="icon" src="@/assets/svg/wind.svg" />
          </div>
          <div>{{ $t("furniture and decoration stores") }}</div>
      </div>
    </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Usecases'
}
</script>

<style scoped>
.feature{
  margin: 3em 0;
  padding-bottom: 3em;
  text-align-last: center;
}
.heading{
  text-transform: uppercase;
  text-align: center;
  padding: 1em 0;
}

.icon{
    width: 7em;
    height: 7em;
    margin: 0.5em 0;
    filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(86deg) brightness(118%) contrast(119%);
}
.col{
  font-size: 1em;
  margin-bottom: 2em;
}
.box{
 background: #2d398f;
 margin: 0 2em;
}
</style>
