<template>
  <div class="feature">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1 class="heading">{{ $t("all in one") }}</h1>
          <p class="lead">You don't need to use different software for each department of location</p>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4 cblue">
          <div><img class="icon" src="@/assets/svg/cpu.svg" /> </div>
          <h2>{{ $t("compliance") }}</h2>
          <p> {{ $t("compliance more") }}</p>
        </div>

        <div class="col-lg-4 cblue">
          <div><img class="icon" src="@/assets/svg/cash-coin.svg" /> </div>
          <h2>{{ $t("affordable price") }}</h2>
          <p>{{ $t("affordable price more") }}</p>
        </div>

        <div class="col-lg-4 cblue sep">
          <div><img class="icon" src="@/assets/svg/hand-index.svg" /> </div>
          <h2>{{ $t("ease of use") }}</h2>
          <p>{{ $t("ease of use more") }}</p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeatureOne'
}
</script>

<style scoped>
.feature{
  margin: 3em 0;
  text-align: center;
  border-bottom:solid 1px #ccc;
  padding-bottom: 3em;
}
.heading{
  text-transform: uppercase;
}
.cblue{
  background: #2d398f;
  color:#fff;
}

.cblue h2{
 padding: 0em 0 0em 0;
}

.cblue p{
 padding: 0em 2em 0em 2em;
}
.sep{
  border-left: solid 1px #666;
}

.icon{
    width: 3em;
    height: 3em;
    margin: 1.5em 0;
    filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(86deg) brightness(118%) contrast(119%);
}

</style>
