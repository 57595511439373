<template>
  <div class="feature" id="business">
    <div class="container">

      <div class="row">
        <div class="col-md-12">
          <h1 class="heading">It has never been easier</h1>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4 plan_1">
          
          <div class="feature_items">
            <h5><img class="icon" src="@/assets/svg/alarm.svg" /> {{ $t("save effort") }} </h5>
            <p>{{ $t("save effort more") }}</p>
          </div>

          <div class="feature_items">
            <h5><img class="icon" src="@/assets/svg/reception-3.svg" /> {{ $t("track activity") }} </h5>
            <p>{{ $t("track activity more") }}</p>
          </div> 

          <div class="feature_items">
            <h5><img class="icon" src="@/assets/svg/shuffle.svg" /> {{ $t("flexibility") }} </h5>
            <p>{{ $t("flexibility more") }}</p>
          </div>

        </div>

        <div class="col-lg-4 plan_2">
          <img src="@/assets/mobile.png" />
        </div>

        <div class="col-lg-4 plan_3">

          <div class="feature_items">
            <h5><img class="icon" src="@/assets/svg/file-lock.svg" /> {{ $t("private database") }}</h5>
            <p>{{ $t("private database more") }}</p>
          </div>

          <div class="feature_items">
            <h5><img class="icon" src="@/assets/svg/safe.svg" /> {{ $t("safety and protection") }}</h5>
            <p>{{ $t("safety and protection more") }}</p>
          </div> 

          <div class="feature_items">
            <h5><img class="icon" src="@/assets/svg/binoculars.svg" />{{ $t("technical support") }}</h5>
            <p>{{ $t("technical support more") }}</p>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeatureThree'
}
</script>

<style scoped>
.feature{
  margin: 3em 0 0 0;
  padding-bottom: 3em;
  background: #2d398f;
  color:#fff;
}
.heading{
  text-transform: uppercase;
  text-align: center;
  padding: 1em 0;
}

.feature_items p{
  padding: 0 0 1.5em 2.2em;
  
}
.plan_1, .plan_3 {
  padding: 0em 3em;
}

.plan_2 {
  text-align: center;
}
.plan_2 img {
  width:70%
}

.icon{
    filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(86deg) brightness(118%) contrast(119%);
}
</style>
