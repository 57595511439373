import { createStore } from 'vuex'

let Lang = () => {
  if(localStorage.getItem("lang") === null){
    return 'en'
  }
  return localStorage.getItem("lang");
}


export default createStore({
  state: {
    lang: Lang()
  },
  mutations: {
    setLang(state, data) {
      localStorage.setItem("lang", data);
      state.lang = data;
    }
  },
  actions: {
  },
  modules: {
  }
})
